import {gql, useQuery} from "@apollo/client";
import React from "react";
import styles from './DishesList.scss'
import Dish from '../Dish/Dish'
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";
import DishesListSub from "../DishesListSub/DishesListSub"

const dishes = gql`
query GetDishes {
specialCategories {
    data {
      id,
      attributes {
        Name,
        dishes(sort: "rank"){
          data {
            attributes {
              Name,
              Price,
              Description,
              Weight,
              Photo {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  mainCategories {
    data {
      id,
      attributes {
        menu_categories(sort: "rank") {
          data {
            id,
            attributes {
              Name,
              description,
              dishes(sort: "rank") {
                data {
                  attributes {
                    Name,
                    Description,
                    Weight,
                    Price,
                    Photo {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default function DishesList() {
    const {id} = useParams();
    const {data, error, loading} = useQuery(dishes);

    useEffect(() => {
        if (document.querySelector('.special-categories.horizontal')) {
            document.querySelector('.special-categories.horizontal').scrollIntoView({
                block: "start",
                behavior: "smooth"
            });
        }
    });


    if (loading) return <Preloader/>
    if (error) return <Error/>
    if (data && !id.includes('special')) {
        return (
            <div className='dishes-list'>
                {data.mainCategories.data
                    .filter(el => el.id === id)
                    .map(el => (
                        <div key={el.id}>
                            {el.attributes.menu_categories.data.map(subcat => (
                                    <DishesListSub key={subcat.id} title={subcat.attributes.Name} subcat={subcat} />
                            ))}
                        </div>
                    ))}
            </div>
        )
    } else if (data && id.includes('special')) {
        return (
            <div className='dishes-list'>
                {data.specialCategories.data
                    .filter(el => el.id === id.split('-')[0])[0]
                    .attributes.dishes.data.map(dish => (
                        <div key={dish.id} className='dish-wrapper'>
                            <Dish title={dish.attributes.Name} description={dish.attributes.Description}
                                  weight={dish.attributes.Weight} price={dish.attributes.Price}
                                  photo={dish.attributes.Photo}/>
                        </div>
                    ))
                }
            </div>
        )
    }
}

