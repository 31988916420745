import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {ApolloClient, InMemoryCache, ApolloProvider} from "@apollo/client";

import Homepage from "./pages/Homepage";
import Menu from "./pages/Menu";

import {localhost} from "./localhost";

const client = new ApolloClient(
    {
        uri: localhost + "/graphql",
        cache: new InMemoryCache(),
    }
);


function App() {
    return (
        <Router>
            <ApolloProvider client={client}>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Homepage/>}/>
                        <Route path="/menu/:id" element={<Menu/>}/>
                    </Routes>
                </div>
            </ApolloProvider>
        </Router>
    );
}

export default App;
