import React from "react";

import CategoriesSidebar from "../components/CategoriesSidebar/CategoriesSidebar";
import SpecialCategoriesSidebar from "../components/SpecialCategoriesSidebar/SpecialCategoriesSidebar";
import DishesList from "../components/DishesList/DishesList";
import MenuHeader from "../components/MenuHeader/MenuHeader";

export default class Menu extends React.Component {

    render() {
        return (
            <>
                <MenuHeader/>
                <div className="menu-page__wrapper container">
                    <div className="menu-page__sidebar">
                        {/*<SpecialCategoriesSidebar vertical={true}/>*/}
                        <CategoriesSidebar />
                    </div>
                    <div className="menu-page__content">
                        <SpecialCategoriesSidebar vertical={false}/>
                        <DishesList />
                    </div>
                </div>
            </>
        )
    }
}