import React from "react";
import styles from "./HomeHeader.scss"
import {localhost} from "../../localhost";

export default function HomeHeader({restaurant, address, logo, photo, map}) {
    return (
        <div className="home-header">
            <img className="home-header__bg" src={localhost + photo + "?width=1920?format=jpeg?quality=80"} alt=""/>
            <div className="container">
                <a className="home-header__logo-box box" href={map} target="_blank">
                    <div className="home-header__logo">
                        <img src={localhost + logo} alt=""/>
                    </div>
                    <div className="home-header__title">
                        <h2>{restaurant}</h2>
                        <address>{address}</address>
                    </div>
                </a>
            </div>
        </div>
    );
}