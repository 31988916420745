import React from "react";
import {Link} from 'react-router-dom'
import useFetch from "../../hooks/useFetch";
import Cart from "../Cart/Cart"
import {localhost} from "../../localhost";

import styles from "./MenuHeader.scss"
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";

export default function MenuHeader() {

    const {data, error, loading} = useFetch(localhost + '/api/about?populate=*');

    function openMobileMenu(e) {
        e.currentTarget.classList.toggle('open');
        if(document.querySelector('.menu-page__sidebar')) {
            document.querySelector('.menu-page__sidebar').classList.toggle('active');
        }
    }


    if (error) return <Error />
    if (data) {
        return (
            <div className="menu-header">
                <div className="container">
                    <div className="menu-header__logo-container">
                        <Link to="/">
                            <div className="menu-header__logo">
                                <img src={localhost + data.data.attributes.Logo.data.attributes.url}
                                     alt=""/>
                            </div>
                        </Link>
                        <div className="menu-header__identity">
                            <h2 className="menu-header__restaurant-name">{data.data.attributes.Restaurant}</h2>
                            <address className="menu-header__address">{data.data.attributes.Address}</address>
                        </div>
                    </div>

                    <div>
                        <Cart/>
                        <ul className="menu-header__language-switch">
                            <li className="menu-header__language-switch--item"></li>
                        </ul>
                    </div>

                    <div className="wrapper-menu" onClick={openMobileMenu}>
                        <div className="line-menu half start"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu half end"></div>
                    </div>
                </div>
            </div>
        );
    }

}