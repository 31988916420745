import React from "react";
import ReactMarkdown from "react-markdown";
import {localhost} from "../../localhost";

import styles from './About.scss';

import locationIcon from './location.png';
import clockIcon from './clock.png';
import phoneIcon from './phone.png';
import instagramIcon from './instagram.png';
import facebookIcon from './facebook.png';


export default function About({title, description, address, hours, phone, instagram, facebook, delivery}) {
    let facebookLine = '';
    let instagramLine = ''

    if (facebook) {
        facebookLine =
            <a href={facebook} target='_blank' rel='noreferrer' className='social-icon'><img src={facebookIcon} alt=""/></a>;
    }

    if (instagram) {
        instagramLine =
            <a href={instagram} target='_blank' rel='noreferrer' className='social-icon'><img src={instagramIcon}
                                                                                              alt=""/></a>;
    }
    return (
        <div className="about box">
            <h2 className="about__title">{title}</h2>
            <div className="about__desc">
                <ReactMarkdown>{description}</ReactMarkdown>
            </div>
            {(facebookLine || instagramLine) ? (<div className="about__social-links">
                {instagramLine}
                {facebookLine}
            </div>) : ('')}
            <div className="about__delivery">
                {delivery.length ? delivery.map(el=> (
                    <a key={el.id} href={el.link ? el.link : ''} title={el.name ? el.name + ' delivery' : ''} className="delivery-icon" target='_blank'
                       rel="noopener noreferrer" >
                        <img src={el.logo ? localhost + el.logo.data.attributes.url : ''} alt={el.name ? el.name + ' delivery' : ''} />
                    </a>
                )) : ''}
            </div>
            <address className="about__address-item address"><img src={locationIcon} alt=""/>
                <div>{address}</div>
            </address>
            <div className="about__address-item working-hours"><img className="clock-icon" src={clockIcon} alt=""/>
                <div><ReactMarkdown>{hours}</ReactMarkdown></div>
            </div>
            <div className="about__address-item phone"><img className="phone-icon" src={phoneIcon} alt=""/><a
                href={'tel:' + phone.split(' ').join('')}>{phone}</a></div>
        </div>
    );
}