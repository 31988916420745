import React from "react";
import ReactMarkdown from "react-markdown";
import {useInView} from 'react-intersection-observer';

import Dish from "../Dish/Dish";

export default function DishesListSub({title, subcat}) {

    const {ref, inView} = useInView({
        threshold: 0.1,
        onChange: (inView, entry) => {
            if(inView) {
                let subCatID = entry.target.id;
                let menuItems = document.querySelectorAll(".subcategories-list > li");
                let shouldBeActive = document.querySelector(`.subcategories-list > li[data-id="${subCatID}"]`)

                if(menuItems.length) {
                    menuItems.forEach(el=>el.classList.remove('active'));
                }

                if(shouldBeActive) {
                    shouldBeActive.classList.add('active');
                }
            }
        }
    });

    return (
        <div ref={ref} key={subcat.id} id={subcat.id} className='dishes-sublist'>
            <h2>{title}</h2>
            <div className="desc"><ReactMarkdown>{subcat.attributes.description}</ReactMarkdown></div>
            {subcat.attributes.dishes.data.map((dish, idx) => (

                <div key={'key-dish-' + idx} className='dish-wrapper'>
                    <Dish title={dish.attributes.Name} description={dish.attributes.Description}
                          weight={dish.attributes.Weight} price={dish.attributes.Price}
                          photo={dish.attributes.Photo}/>
                </div>
            ))}
        </div>

    )
}