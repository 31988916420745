import React from "react";
import {Link} from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import {localhost} from "../../localhost";

import styles from "./SpecialCategoriesSidebar.scss"
import Error from "../Error/Error";

export default function SpecialCategoriesSidebar({vertical}) {
    const {data, error, loading} = useFetch(localhost + '/api/special-categories?order=rank&populate=*');


    if (error) return <Error/>

    if (data && vertical) {

        return (
            <div className="special-categories">
                <ul>
                    {data.data.map(el => {
                        if (el.attributes.file.data) {
                            return (
                                <li key={el.id}><a target='_blank'
                                                   href={localhost + el.attributes.file.data.attributes.url}><h3><img
                                    src={localhost + el.attributes.Icon.data.attributes.url}
                                    alt=""/>{el.attributes.Name}</h3></a></li>)
                        } else {
                            return (<li key={el.id}><Link to={"/menu/" + el.id + "-special"}><h3><img src={localhost + el.attributes.Icon.data.attributes.url}
                                                                                         alt=""/>{el.attributes.Name}</h3></Link></li>)
                        }

                    })}
                </ul>
            </div>
        );
    }

    function specialCategoryCLick(e) {
        let items = document.querySelectorAll('.special-categories.horizontal a');
        items.forEach(el=>el.classList.remove('active'));
        e.currentTarget.classList.add('active');
    }

    if (data && !vertical) {

        return (
            <div className="special-categories horizontal">
                <ul>
                    {data.data.map(el => {
                        if (el.attributes.file.data) {
                            return (<li key={el.id}>
                                <a href={localhost + el.attributes.file.data.attributes.url} target='_blank'>
                                    <div className="icon-wrapper"><img
                                        src={localhost + el.attributes.Icon.data.attributes.url}
                                        alt=""/></div>
                                    <span>{el.attributes.Name}</span></a>

                            </li>)
                        } else {
                            return (<li key={el.id}><Link onClick={specialCategoryCLick} to={"/menu/" + el.id + "-special"}>
                                <div className="icon-wrapper"><img
                                    src={localhost + el.attributes.Icon.data.attributes.url}
                                    alt=""/></div>
                                <span>{el.attributes.Name}</span></Link>
                            </li>);
                        }

                    })}
                </ul>
            </div>
        );
    }

}