import React from "react";
import {useParams, Link} from "react-router-dom";
import {useQuery, gql} from "@apollo/client";

// import Preloader from "../Preloader/Preloader";

import styles from "./CategoriesSidebar.scss"
import Error from "../Error/Error";
import {localhost} from "../../localhost";

const categories = gql`
    query GetCategories {
  mainCategories(sort: "rank") {
    data {
      id,
      attributes {
        Name,
        file {
          data {
            attributes {
              url,
            }
          }
        }
        menu_categories(sort: "rank") {
          data {
            id,
            attributes {
              Name,
              file {
                data {
                  attributes {
                    url,
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default function CategoriesSidebar() {
    const {id} = useParams();
    const {data, error, loading} = useQuery(categories);

    if (error) return <Error/>;

    function clearActiveClass() {

        let specialItems = document.querySelectorAll('.special-categories.horizontal a');
        specialItems.forEach(el=>el.classList.remove('active'));
    }

    function handleClick(e) {

        if (window.matchMedia("(max-width: 767px)").matches) {
            document.querySelector('.menu-page__sidebar').classList.remove('active');
            document.querySelector('.wrapper-menu').classList.remove('open');
        }
        if (e.currentTarget.classList.contains('active')) {
            return;
        } else {
            document.querySelectorAll('.subcategories-list > li.active').forEach(el => {
                el.classList.remove('active');
                e.currentTarget.classList.add('active');
            })
        }
    }

    if (data) {

        return (
            <div className="categories">
                <ul>
                    {data.mainCategories.data.map(el => {
                        if (el.attributes.file.data) {
                            return (<li key={el.id} onClick={clearActiveClass}><h3><a target='_blank'
                                                           href={localhost + el.attributes.file.data.attributes.url}>{el.attributes.Name}</a>
                            </h3></li>)
                        } else {
                            return (<li key={el.id} onClick={clearActiveClass}>
                                <h3 className={el.id === id ? 'active' : ''}><Link
                                    to={"/menu/" + el.id}>{el.attributes.Name}</Link></h3>
                                <ul className={el.id === id ? 'subcategories-list active' : 'subcategories-list'}>
                                    {el.attributes.menu_categories.data.map((subcat, idx) => {
                                        if (subcat.attributes.file.data) {
                                            return (<li key={subcat.id}>
                                                <a target='_blank' href={localhost + subcat.attributes.file.data.attributes.url}>{subcat.attributes.Name}</a></li>);
                                        } else {
                                            return (<li key={subcat.id} data-id={subcat.id} className={idx === 0 ? "active" : ''}
                                                        onClick={handleClick}>
                                                <a href={'#' + subcat.id}>{subcat.attributes.Name}</a></li>);
                                        }
                                    })}
                                </ul>
                            </li>)
                        }
                    })}
                </ul>
            </div>
        );
    }
}