import React from "react";
import {Link} from 'react-router-dom'
import useFetch from "../../hooks/useFetch";
import {localhost} from "../../localhost";

import styles from "./HomeMenu.scss"
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";

export default function HomeMenu() {
    const {data, error, loading} = useFetch(localhost + '/api/main-categories?order=rank&populate=*');

    if (loading) return <Preloader/>
    if (error) return <Error />

    if (data) {

        return (
            <div className="home-menu box">
                <h2>Розділи меню</h2>
                <ul>
                    {data.data.map(el => {
                        if (el.attributes.file.data) {
                            return (<li key={el.id}><a target="_blank" href={localhost + el.attributes.file.data.attributes.url}>{el.attributes.Name}</a>
                            </li>)
                        } else {
                            return (<li key={el.id}><Link to={"/menu/" + el.id}>{el.attributes.Name}</Link></li>)
                        }

                    })}
                </ul>
            </div>
        );
    }

}