import React from "react";
import {Link} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";

import {localhost} from "../localhost";

import HomeHeader from "../components/HomeHeader/HomeHeader";
import HomeMenu from "../components/HomeMenu/HomeMenu";
import About from "../components/About/About";

import useFetch from "../hooks/useFetch";
import Preloader from "../components/Preloader/Preloader";
import Error from "../components/Error/Error";

const homepageQuery = gql`
query GetHomepageInfo {
about {
  data {
    attributes {
      Restaurant,
      Address,
      Logo {
        data {
          attributes {
            url,
          }
        }
      },
      Photo {
        data {
          attributes {
            url,
          }
        }
      },
      map,
      Title,
      Description,
      Hours,
      Phone,
      instagram,
      facebook,
      delivery {
        id,
        link,
        name,
        logo {
          data {
            attributes {
              url,
            }
          }
        }
      }
    }
  }
}
}`

export default function Homepage() {

    const {data, error, loading} = useQuery(homepageQuery);

    if (loading) return <Preloader/>
    if (error) return <Error />

    if (data) {
        let about = data.about.data.attributes;
        return (
            <div>
                <HomeHeader restaurant={about.Restaurant} address={about.Address} logo={about.Logo.data.attributes.url}
                            photo={about.Photo.data.attributes.url} map={about.map}/>
                <div className='container home-page-blocks'>
                    <HomeMenu/>
                    <About title={about.Title} description={about.Description} address={about.Address}
                           hours={about.Hours} phone={about.Phone} instagram={about.instagram} facebook={about.facebook} delivery={about.delivery}/>
                </div>
            </div>
        );
    }
}