import React from "react";
import ReactMarkdown from "react-markdown";
import styles from './Dish.scss'
import {localhost} from "../../localhost";

export default class Dish extends React.Component {

    imgClick = (e) => {
        e.target.classList.toggle('clicked');
    }

    render() {
        return (
            <>
                {this.props.photo.data ?
                    <img onClick={this.imgClick} src={localhost + this.props.photo.data.attributes.url + "?width=600?format=jpeg?quality=80"} alt=""
                         className='dish-image'/> :
                    <div className='dish-no-photo'></div>}
                <div className="dish-info">
                    <h3>{this.props.title}</h3>
                    <div className="desc"><ReactMarkdown>{this.props.description}</ReactMarkdown></div>
                    <p className="weight">{this.props.weight}</p>
                    {this.props.price ?
                    <span className="price">{this.props.price} ₴</span> : <span className="price"></span>}
                </div>
            </>
        );
    }
}